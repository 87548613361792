let data = [

    { firstname: 'camila', lastname: 'lamarca', backgroundPosition: "center", desktopSize: '55%' },
    { firstname: 'carolina', lastname: 'zancolli', backgroundPosition: "50% 30%", desktopSize: '50%' },
    { firstname: 'claudia', lastname: 'cortinez', backgroundPosition: "center", desktopSize: '50%' },
    { firstname: 'florencia', lastname: 'walter', backgroundPosition: "center", desktopSize: '60%' },
    { firstname: 'samuel', lastname: 'lasso', backgroundPosition: "60% 70%", desktopSize: '60%' },
    { firstname: 'sol', lastname: 'pochat', backgroundPosition: "center", desktopSize: '60%' }

];

export default data;